<template>
    <div class="box my-account">
        <div class="compant">
            <el-form :model="compantData" :rules="rules" ref="ruleForm" label-width="140px">
                <el-form-item label="是否是预算单位" prop="is_budget">
                    <p v-if="compantData.is_budget == 1">是</p>
                    <p v-else>否</p>
                </el-form-item>
                <el-form-item label="单位名称" prop="org_name">
                    <el-input v-model="compantData.org_name" placeholder="单位名称"></el-input>
                </el-form-item>
                <!--                <el-form-item label="单位类型" prop="org_type">-->
                <!--                    <el-select v-model="compantData.org_type" placeholder="请选择注册类型">-->
                <!--                        <el-option-->
                <!--                            v-for="item in companytype"-->
                <!--                            :key="item.type"-->
                <!--                            :label="item.name"-->
                <!--                            :value="item.type">-->
                <!--                        </el-option>-->
                <!--                    </el-select>-->
                <!--                </el-form-item>-->
                <el-form-item label="统一社会信用代码" prop="social_credit_code">
                    <el-input v-model="compantData.social_credit_code" placeholder="请输入统一社会信用代码"></el-input>
                </el-form-item>
                <div class="regionSelect">
                    <el-form-item label="单位所在地" prop="region">
                        <el-select v-model="province" placeholder="请选择省" @change="provinceChange">
                            <el-option v-for="item in provinceOptions" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="city" placeholder="请选择市" @change="cityChange">
                            <el-option v-for="item in cityOptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="area" placeholder="请选择区县">
                            <el-option v-for="item in areaOptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item label="详细地址" prop="address">
                    <el-input v-model="compantData.address" placeholder="请输入详细地址"></el-input>
                </el-form-item>
            </el-form>
            <div style="text-align: center">
                <el-button @click="submitCompany('ruleForm')" style="width: calc(100% - 60px)" type="primary">保存
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { registerAddressLists } from '@/api/auth/register';
import { memberOrgDetail, memberSaveOrgInfo, memberTypeList } from '@/api/member/index';
import { getToken } from '@/utils/auth';
export default {
    name: "company",
    data() {
        return {
            province: '',
            city: '',
            area: '',
            provinceOptions: [],
            cityOptions: [],
            areaOptions: [],
            companytype: [],
            compantData: {
                org_name: "",
                org_type: "",
                region: "",
                social_credit_code: "",
            },
            rules: {
                org_name: [
                    { required: true, message: "单位名称", trigger: "blur" },
                    { min: 3, max: 20, message: "长度在 3 到 20 个字符", trigger: "blur" }
                ],
                address: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                    { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }],
                org_type: [
                    { required: true, message: "请选择注册类型", trigger: "blur" },
                ],
                social_credit_code: [
                    { required: true, message: "请输入统一社会信用代码", trigger: "blur" },
                ],
                region: [
                    { required: true, message: "请选择地区", trigger: "blur" },
                ],
            }
        }
    },
    mounted() {
        this.provinceData(); // 获取地市
        this.memberOrgDetailData(); // 单位详情
        this.memberTypeListData(); // 单位类型接口
    },
    methods: {
        // 保存单位详情
        memberSaveOrgInfoData() {
            let province_name = ''
            let city_name = ''
            let district_name = ''
            this.provinceOptions.map((item) => {
                if (this.province == item.id) {
                    province_name = item.name
                }
            })
            this.cityOptions.map((item) => {
                if (this.city == item.id) {
                    city_name = item.name
                }
            })
            this.areaOptions.map((item) => {
                if (this.area == item.id) {
                    district_name = item.name
                }
            })
            let data = {
                org_name: this.compantData.org_name,
                org_type: this.compantData.org_type,
                social_credit_code: this.compantData.social_credit_code,
                address: this.compantData.address,
                province: this.province, // 省id
                province_name: province_name, //省
                city: this.city, // 市id
                city_name: city_name, // 市
                district: this.area, // 区县id
                district_name: district_name, // 区县
            }
            // console.log(data)
            memberSaveOrgInfo(data).then(res => {
                this.$message.success('保存成功！')
            })
        },
        // 获取单位详情
        async memberOrgDetailData() {
            let data = await this.memberOrgDetailDataAsync()
            // console.log('获取单位详情', data)
            let list = {
                org_name: data.org_name,
                org_type: data.org_type,
                social_credit_code: data.social_credit_code,
                address: data.address,
            }
            this.compantData = list
            this.province = data.province
            this.city = data.city
            this.area = data.district == 0 ? '' : data.district
            this.cityOptions = await this.registerAddressListsData({ id: data.province, pid: data.province })
            this.areaOptions = await this.registerAddressListsData({ id: data.city, pid: data.city })

        },
        // 单位详情接口
        async memberOrgDetailDataAsync() {
            let text = {
            }
            return new Promise(resolve => {
                memberOrgDetail(text).then(res => {
                    let data = res.data
                    resolve(data)
                })
            })

        },
        // 单位类型接口
        memberTypeListData() {
            memberTypeList().then(res => {
                this.companytype = res.data.list
            })
        },
        // 提交
        submitCompany(formName) {
            // || this.area == ''
            if (this.province == '' || this.city == '') {
                this.compantData.region = ''
            } else {
                this.compantData.region = 1
            }
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.memberSaveOrgInfoData()
                } else {
                    return false;
                }
            })
        },
        // 省选择事件
        async provinceChange(value) {
            this.city = ''
            this.cityOptions = []
            this.area = ''
            this.areaOptions = []
            this.cityOptions = await this.registerAddressListsData({ id: value, pid: value })
        },
        // 市选择事件
        async cityChange(value) {
            this.area = ''
            this.areaOptions = []
            this.areaOptions = await this.registerAddressListsData({ id: value, pid: value })
        },
        async provinceData() {
            this.provinceOptions = await this.registerAddressListsData()
        },
        // 获取地址
        async registerAddressListsData(city = {}) {
            let type = {
                ...city,
                app_type: "pc",
                app_type_name: "电脑端",
            }
            return new Promise(resolve => {
                registerAddressLists(type).then(res => {
                    let data = res.data
                    resolve(data)
                })
            })

        },
    }
}
</script>

<style scoped type="text/scss" lang="scss">
.regionSelect {
    text-align: left;

    ::v-deep .el-form-item__content {
        display: flex;
    }
}

.my-account {
    background: #ffffff;
    padding: 20px;

    .compant {
        width: 500px;
    }
}
</style>
